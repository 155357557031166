<template>  
  <div class="home-container">  
    <div class="linear-bg"></div>  
    <div class="home-scroll">  
      <div class="banner">  
        <swiper class="banner_swiper" :options="bannerSwiperOption">  
          <swiper-slide v-for="(v, key) in banners" :key="key">  
            <van-image class="banner_img" round :src="v.url">  
              <template v-slot:loading>  
                <van-loading type="circular" />  
              </template>  
            </van-image>  
          </swiper-slide>  
        </swiper>  
      </div>  
      <div class="notice-bar">  
        <van-notice-bar  
          class="notice-swipe"  
          left-icon="bullhorn-o"  
          background="#ffffff"  
          color="#7e5678"  
          :text="this.notice"  
        />  
        <div class="linear-gradient"></div>  
      </div>  
      <div class="info-display">  
        <div class="info-item" v-for="(info, index) in infos" :key="index">  
          <van-image class="info-img" round :src="info.imgUrl" />  
          <div class="info-text">  
            <p class="info-details">  
              <span class="info-name">{{ info.name }}</span>  
              <span class="info-age">{{ info.age }}歳</span>  
              <span class="info-area">{{ info.area }}</span>  
              <span class="info-personality">{{ info.personality }}</span>  
            </p>  
          </div>  
        </div>  
      </div>  
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  data() {  
    return {  
      notice: "获取中......",  
      banners: [{}],  
      infos: [  
        { imgUrl: '/img/sy/1.jpg', name: '美咲(Misaki)', age: 28, area: '東京', personality: '明るい' },  
        { imgUrl: '/img/sy/2.jpg', name: '芳恵(Yoshie', age: 24, area: '京都', personality: '内向' },  
        { imgUrl: '/img/sy/3.jpg', name: '由美(Yumi)', age: 30, area: '大阪', personality: '活発である' },  
        { imgUrl: '/img/sy/4.jpg', name: '直子(Naoko)', age: 26, area: '名古屋', personality: 'しっかりしている' },  
        { imgUrl: '/img/sy/5.jpg', name: '美和(Miwa)', age: 29, area: '広島', personality: '情熱' },  
        { imgUrl: '/img/sy/6.jpg', name: '涼子(Ryoko)', age: 27, area: '横浜', personality: '落ち着いて' }  
      ],  
      bannerSwiperOption: {  
        effect: 'coverflow',  
        grabCursor: true,  
        centeredSlides: true,  
        slidesPerView: 'auto',  
        speed: 800,  
        autoplay: true,  
        coverflowEffect: {  
          rotate: 50,  
          stretch: 10,  
          depth: 100,  
          modifier: 1,  
          slideShadows: true  
        }  
      }  
    };  
  },  
  methods: {  
    getBasicConfig() {  
      this.$http({  
        method: 'get',  
        url: 'sys_config'  
      }).then(res => {  
        this.getNotice(res.data);  
        this.getBanner(res.data);  
        // 假设你也可以从res.data中获取infos数据  
        // this.infos = res.data.infos;  
      });  
    },  
    getNotice(data) {  
      this.notice = data.notice;  
    },  
    getBanner(data) {  
      this.banners = data.banners;  
    }  
  },  
  mounted() {},  
  created() {  
    this.getBasicConfig();  
  }  
};  
</script>  
  
<style lang='less' scoped>  
@notice-bar-size: 30px;  
  
.linear-bg {  
  height: 200px;  
  background: linear-gradient(270deg, #e6c3a1, #7e5678);  
}  
  
.home-container {  
  position: absolute !important;  
  top: 0;  
  left: 0;  
  right: 0;  
  background-color: #ffffff;  
}  
  
.linear-gradient {  
  width: 100%;  
  height: 2px;  
  background: linear-gradient(  
    to right,  
    rgba(126, 86, 120, 0),  
    rgb(230, 195, 161),  
    rgba(126, 86, 120, 0)  
  );  
}  
  
::v-deep .van-notice-bar__left-icon,  
.van-notice-bar__right-icon {  
  min-width: 40px;  
}  
  
.notice-swipe {  
  width: calc(100% - 50px);  
  height: 85px;  
  font-size: @notice-bar-size;  
}  
  
::v-deep .van-icon-bullhorn-o::before {  
  transform: scale(2.5);  
}  
  
.banner {  
  width: 100%;  
  margin-top: -23%;  
}  
  
.banner_swiper {  
  height: 100%;  
  width: 100%;  
  
  .swiper-slide {  
    border-radius: 10px;  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    width: 620px;  
    height: 300px;  
    text-align: center;  
    font-weight: bold;  
    font-size: 20px;  
    background-color: #ffffff;  
    background-position: center;  
    background-size: cover;  
    color: #ffffff;  
  }  
}  
  
::v-deep .swiper-container-3d .swiper-slide-shadow-left {  
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));  
}  
  
::v-deep .swiper-container-3d .swiper-slide-shadow-right {  
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));  
}  
  
.banner_img {  
  border-radius: 10px;  
  width: 100%;  
  height: 100%;  
}  
  
.info-display {  
  display: flex;  
  flex-wrap: wrap;  
  justify-content: space-around;  
  padding: 10px;  
}  
  
.info-item {  
  width: 45%;  
  margin-bottom: 80px;  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
}  
  
.info-img {  
  width: 350px;  
  height: 450px;  
  margin-bottom: 10px;  
  border-radius: 0;  
}  
  
.info-text {  
  text-align: center;  
}  
  
.info-details {  
  display: flex;  
  flex-wrap: wrap;  
  justify-content: center;  
}  
  
.info-name {  
  font-size: 30px;  
  font-weight: bold;  
  margin-right: 10px;  
}  
  
.info-age {  
  font-size: 30px;  
  color: #ff0000;  
  margin-right: 5px;  
}  
  
.info-area {  
  font-size: 30px;  
  color: #ff5500;  
  margin-right: 5px;  
}  
  
.info-personality {  
  font-size: 30px;  
  color: #55aa00;  
  margin-right: 5px;
  
}  
</style>